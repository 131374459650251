<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.seeproblem')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【banner搜索】 -->
        <div class="pc-banner">
            <b-container class="pc-centrr">
                <div class="pc-bannersearch">
                    <div class="pc-btnicon"><span class="el-icon-search"></span></div>
                    <div class="pc-btninput">
                        <input type="text" v-model="searchName" :placeholder="$t('message.searchsee')"/>
                        <span v-show="searchName!=''" class="pc-cancel" @click="getCancel">×</span>
                    </div>
                    <div class="pc-btnsear" @click="getSearch">{{$t('message.search')}}</div>
                </div>
            </b-container>
        </div>
        <!-- wap布局【banner搜索】 -->
        <div class="wap-banner">
            <b-container class="wap-centrr">
                <b-row><div class="wap-banner-title">{{$t('message.welcom')}}</div></b-row>
                <b-row>
                    <div class="wap-bannersearch">
                        <div class="wap-btnicon"><span class="el-icon-search"></span></div>
                        <div class="wap-btninput">
                            <input type="text" v-model="searchName" :placeholder="$t('message.searchsee')"/>
                            <span v-show="searchName!=''" class="wap-cancel" @click="getCancel">×</span>
                        </div>
                        <div class="wap-btnsear" @click="getSearch">{{$t('message.search')}}</div>
                    </div>
                </b-row>
            </b-container>
        </div>
        <!-- pc和wap布局【banner搜索结果】 -->
        <b-container class="pc-search-tab" v-show="searchName&&dataSearch.length>0" >
            <b-row>
                <div class="pc-title">{{$t('message.searchend')}}</div>
                <div class="pc-step-item" v-for="(item,index) in dataSearch" :key="index" v-show="dataSearch.length>0">
                    <div class="pc-step-item-box">
                        <div class="pc-step-item-group-left">
                            <div class="pc-step-item-data" @click="getShowSearch(item)">
                                {{languageName=='cn'?item.cn_title:''}}
                                {{languageName=='en'?item.en_title:''}}
                                {{languageName=='it'?item.it_title:''}}
                            </div>
                            <div class="pc-step-item-dtxt" v-show="item.flag">
                                <div v-show="languageName=='cn'" v-html="item.cn_content"></div>
                                <div v-show="languageName=='en'" v-html="item.en_content"></div>
                                <div v-show="languageName=='it'" v-html="item.it_content"></div>
                                <!-- {{languageName=='cn'?item.cn_description:''}}
                                {{languageName=='en'?item.en_description:''}}
                                {{languageName=='it'?item.it_description:''}} -->
                            </div>
                        </div>
                        <div class="pc-step-item-group-right" @click="getShowSearch(item)">
                            <span :class="item.flag?'el-icon-arrow-up':'el-icon-arrow-down'" ></span>
                        </div>
                    </div>
                </div>
            </b-row>
        </b-container>
        <b-container class="wap_pc-search-tab" v-show="searchfalg&&listdata.length==0">
            <b-row>
                <div class="pc-title">{{$t('message.searchend')}}</div>
                <div class="pc-step-item-ondata">
                   {{$t('message.nodata')}}~
                </div>
            </b-row>
        </b-container>
        <!-- pc布局【问题类型和数据列表】 -->
        <b-container class="pc-router-tab" v-show="tabData.length>0&&listdata.length==0">
            <b-row class="pc-project-box">
                <b-col class="pc-cent" v-for="(item,index) in tabData" :key="index">
                    <div class="pc-router-group" @click="getTab(item,index)">
                        <div class="pc-router-group-icon" :class="dataIndex==index?'iconed'+(index+1):'icon'+(index+1)"></div>
                        <div class="pc-router-group-txt" :class="dataIndex==index?'pc-cur':''">
                            {{languageName=='cn'?item.cn_title:''}}
                            {{languageName=='en'?item.en_title:''}}
                            {{languageName=='it'?item.it_title:''}}
                        </div>
                        <div><span class="el-icon-arrow-down" :class="dataIndex==index?'pc-cur':''"></span></div>
                    </div>
                </b-col>
            </b-row>
            <div class="pc-router-data"> 
                <div class="pc-step-item" v-for="(item,index) in childlist" :key="index">
                    <div class="pc-step-item-box">
                        <div class="pc-step-item-group-left">
                            <div class="pc-step-item-data" @click="getShowSearch(item)">
                                {{languageName=='cn'?item.cn_title:''}}
                                {{languageName=='en'?item.en_title:''}}
                                {{languageName=='it'?item.it_title:''}}
                            </div>
                            <div class="pc-step-item-dtxt" v-show="item.flag">
                                <!-- {{languageName=='cn'?item.cn_description:''}}
                                {{languageName=='en'?item.en_description:''}}
                                {{languageName=='it'?item.it_description:''}} -->
                                <div v-show="languageName=='cn'" v-html="item.cn_content"></div>
                                <div v-show="languageName=='en'" v-html="item.en_content"></div>
                                <div v-show="languageName=='it'" v-html="item.it_content"></div>
                            </div>
                        </div>
                        <div class="pc-step-item-group-right" @click="getShowSearch(item)">
                            <span :class="item.flag?'el-icon-arrow-up':'el-icon-arrow-down'" ></span>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- wap布局【问题类型和数据列表】 -->
        <b-container class="wap-router-tab"  v-show="tabData.length>0&&listdata.length==0">
            <b-row class="wap-router-box" v-for="(item,index) in tabData" :key="index">
                <div class="wap-router-title" :class="dataIndex==index?'wap-cur':''" @click="getTab(item,index)">
                    {{languageName=='cn'?item.cn_title:''}}
                    {{languageName=='en'?item.en_title:''}}
                    {{languageName=='it'?item.it_title:''}}
                </div>
                <div class="wap-router-list" v-show="dataIndex==index">
                    <div class="wap-router-li-item" v-for="(v,k) in childlist" :key="k">
                        <div class="step-item-box">
                            <div class="step-item-group-left">
                                <div class="step-item-data" @click="getShowSearch(v)">
                                    {{languageName=='cn'?v.cn_title:''}}
                                    {{languageName=='en'?v.en_title:''}}
                                    {{languageName=='it'?v.it_title:''}}
                                </div>
                                <div class="step-item-dtxt" v-show="v.flag">
                                    <div v-show="languageName=='cn'" v-html="v.cn_content"></div>
                                    <div v-show="languageName=='en'" v-html="v.en_content"></div>
                                    <div v-show="languageName=='it'" v-html="v.it_content"></div>
                                    <!-- {{languageName=='cn'?item.cn_description:''}}
                                    {{languageName=='en'?item.en_description:''}}
                                    {{languageName=='it'?item.it_description:''}} -->
                                </div>
                            </div>
                            <div class="step-item-group-right" @click="getShowSearch(v)">
                                <span :class="v.flag?'el-icon-arrow-up':'el-icon-arrow-down'" ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang} from '@/untils/common';
export default {
    name:'problem',
    data() {
        return {
            languageName:'',
            languageObj:{},
            searchName:'',
            searchfalg:false,
            listdata:[],
            dataSearch:[],
            productListRst:{},
            dataIndex:0,
            tabData:[],
            childlist:[],
        }
    },
    methods: {
        // 一级tab切换
        getTab(item,index){
            this.dataIndex = index;
            this.getChind(item);
        },
        // 一级列表数据
        getTablist(){
            this.$http.api_article_twotype({id:10}).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.tabData = res.data;
                            this.getChind(res.data[0]);
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 二级列表数据
        getChind(item){
            if(item){
                this.$http.api_article_list({
                    typeid:10,// 	一级类型id 	
                    typetwoid:item.id,// 	二级类型id 	
                    page:1,// 	页数 默认1 	
                    size:10,// 	每页条数 默认10
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data&&res.data.list.length>0){
                                res.data.list.map((v)=>{
                                    v.flag = false;
                                })
                                this.childlist = res.data.list;
                            }
                        }else{
                            // ElementUI.Message({
                            //     message:res.message,
                            //     type:'error',
                            //     center: true,
                            //     offset:10,
                            // });
                            return false;
                        }
                    }
                });
            }
        },
        // 搜索数据
        getSearch(){
            if(this.searchName){
                // 只展示二级列表数据
                this.$http.api_article_list({
                    typeid:10,// 	一级类型id 	
                    page:1,// 	页数 默认1 	
                    size:10,// 	每页条数 默认10
                    content:this.searchName
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data&&res.data.list.length>0){
                                res.data.list.map((v)=>{
                                    v.flag = false;
                                });
                                this.dataSearch = res.data.list;
                                this.tabData = [];
                                this.listdata = [];
                                // this.searchInputChange(this.searchName);
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else{
                ElementUI.Message({
                    message:this.languageObj.waittxt,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 前端搜索
        searchInputChange(value) {
            // 若未输入值，则展示所有数据
            if(null === value || undefined === value){
                this.productListRst = this.dataSearch;
            } else {
                this.productListRst = []; // 结果列表置空
                let regStr =  '';
                // 初始化正则表达式
                for(let i=0; i<value.length; i++){
                    regStr = regStr + '(' + value[i] + ')([\\s]*)'; //跨字匹配
                }
                let reg = new RegExp(regStr);
                for(let i=0; i<this.dataSearch.length; i++){
                    let name = this.dataSearch[i].title; //按照名字匹配
                    let regMatch = name.match(reg);
                    if(null !== regMatch) {// 将匹配的数据放入结果列表中
                        this.productListRst.push(this.dataSearch[i]);
                    }
                }
                if(this.productListRst.length==0){
                    this.searchfalg = true;
                }else{
                    this.listdata = this.productListRst;
                }
            }
        },
        // 去掉搜索内容数据
        getCancel(){
            this.searchfalg = false;
            this.searchName = '';
            this.listdata = [];
            this.getTablist();
        },
        // 二级数据展示&隐藏
        getShowSearch(item){
            if(item){
                item.flag=!item.flag;
            }
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "waittxt":"请输入搜索内容",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "waittxt":"Please enter the search content",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "waittxt":"Inserisci il contenuto della ricerca",
            }
        }
        this.getTablist();// 一级列表数据
    }
}
</script>
<style lang="scss" scoped>
.navtab{
    padding:20px 10px 10px;
    
}
.pc-banner{
    display:flex;
    margin-top: 10px;
    height:390px;
    width: 100%;
    background: url(~@/assets/images/problem_banner.png) no-repeat left top;
    background-size: 100% 100%;
    .pc-centrr{
        display:flex;
        justify-content:center;
        align-items:center;
        .pc-bannersearch{
            display: flex;
            -webkit-box-orient: horizontal;
            width:60%;
            height:50px;
            line-height:50px;
            border-radius:4px;
            .pc-btnicon{
                width:8%;
                text-align: center;
                background: #fff;
                border-top-left-radius:4px;
                border-bottom-left-radius:4px;
                .el-icon-search{
                    line-height:50px;
                    font-size: 30px;
                }
            }
            .pc-btninput{
                width: 77%;
                position: relative;
                input{
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    padding:0 10px;
                }
                .pc-cancel{
                    display: inline-block;
                    position: absolute;
                    cursor: pointer;
                    right: 0;
                    top:0;
                    height:40px;
                    font-size:20px;
                    padding:0 10px;
                    background: #fff;
                }
            }
            .pc-btnsear{
                width:15%;
                cursor: pointer;
                text-align: center;
                color: #fff;
                background: #1f73b7;
                border-top-right-radius:4px;
                border-bottom-right-radius:4px;
            }
        }
    }
}
.wap_pc-search-tab{
    padding-top:30px;
    .row{
        padding: 0 0 0 0;
        margin: 0 0 50px 0;
        .pc-title{
            width: 100%;
            padding:20px;
            text-align: center;
            font-size:30px;
        }
        .pc-step-item-ondata{
            width: 100%;
            background: #f4f7fe;
            padding:20px;
            text-align: center;
        }
    }
}
.pc-search-tab{
    padding-top:30px;
    .row{
        padding: 0 0 0 0;
        margin: 0 0 50px 0;
        .pc-title{
            width: 100%;
            padding:20px;
            text-align: center;
            font-size:30px;
        }
        .pc-step-item-ondata{
            width: 100%;
            background: #f4f7fe;
            padding:20px;
            text-align: center;
        }
        .pc-step-item{
            width: 100%;
            background: #f4f7fe;
            padding:0 20px;
            .pc-step-item-box{
                width: 100%;
                cursor: pointer;
                display: flex;
                -webkit-box-orient: horizontal;
                border-bottom:1px solid #eff2f9;
                padding: 15px 0;
                .pc-step-item-group-left{
                    width:95%;
                    .pc-step-item-dtxt{
                        color: #616166;
                        padding: 25px 0 10px;
                    }
                }
                .pc-step-item-group-right{
                    width: 5%;
                    text-align: center;
                    .el-icon-arrow-down{font-size:20px;}
                    .el-icon-arrow-up{font-size:20px;}
                }
            }
        }
    }
    
}
.pc-router-tab{
    padding-top:30px;
    .pc-project-box{
        padding-bottom: 20px;
        .pc-cent{
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            .pc-router-group{
                width:100%;
                cursor: pointer;
                text-align: center;
                .pc-router-group-icon{
                    display: inline-block;
                    width:40px;
                    height:40px;
                }
                .icon1{
                    background: url(~@/assets/images/product.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .icon2{
                    background: url(~@/assets/images/takeover.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .icon3{
                    background: url(~@/assets/images/paysave.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .icon4{
                    background: url(~@/assets/images/repair.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .iconed1{
                    background: url(~@/assets/images/producted.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .iconed2{
                    background: url(~@/assets/images/takeovered.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .iconed3{
                    background: url(~@/assets/images/paysaveed.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .iconed4{
                    background: url(~@/assets/images/repaired.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .pc-router-group-txt{
                    padding:5px 0;
                    text-align: center;
                }
                .pc-cur{
                    color: #1F73B7;
                }
            }
        }
    }
    .pc-router-data{
        background: #f4f7fe;
        padding:20px 40px;
        margin-bottom: 130px;
        .pc-step-item{
            .pc-step-item-box{
                width: 100%;
                cursor: pointer;
                display: flex;
                -webkit-box-orient: horizontal;
                border-bottom:1px solid #eff2f9;
                padding: 15px 0;
                .pc-step-item-group-left{
                    width:95%;
                    .pc-step-item-dtxt{
                        color: #616166;
                        padding: 25px 0 10px;
                    }
                }
                .pc-step-item-group-right{
                    width: 5%;
                    text-align: center;
                    
                    .el-icon-arrow-down{font-size:20px;}
                    .el-icon-arrow-up{font-size:20px;}
                }
            }
        }
    }
    
}

/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-router-tab{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-router-tab{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-router-tab{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-banner{display: none;}
        .pc-router-tab{display: none;}
        .wap-banner{
            display:flex;
            margin-top: 10px;
            height:190px;
            width: 100%;
            background: url(~@/assets/images/problem_banner.png) no-repeat left top;
            background-size: 100% 100%;
            .wap-centrr{
                width: 100%;
                .wap-banner-title{
                    width: 100%;
                    padding:30px;
                    font-size: 30px;
                    text-align: center;
                }
                .wap-bannersearch{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    width: 80%;
                    height:40px;
                    line-height:40px;
                    margin: 0 auto;
                    background: #fff;
                    border-top-left-radius:4px;
                    border-bottom-left-radius:4px;
                    .wap-btnicon{
                        width:15%;
                        text-align: center;
                        background: #fff;
                        border-top-left-radius:4px;
                        border-bottom-left-radius:4px;
                        .el-icon-search{
                            line-height:40px;
                            font-size:20px;
                        }
                    }
                    .wap-btninput{
                        width: 65%;
                        position: relative;
                        border-radius:0;
                        input{
                            width: 100%;
                            height: 100%;
                            border: none;
                            outline: none;
                            padding:0 10px;
                            
                            border-radius:0;
                            background: transparent;
                        }
                        .wap-cancel{
                            display: inline-block;
                            position: absolute;
                            cursor: pointer;
                            right: 0;
                            top:0;
                            height:40px;
                            font-size:20px;
                            padding:0 10px;
                            background: #fff;
                        }
                    }
                    .wap-btnsear{
                        width:20%;
                        text-align: center;
                        
                        color: #fff;
                        background: #1f73b7;
                        border-top-right-radius:4px;
                        border-bottom-right-radius:4px;
                    }
                }
            }
            
        }
        .wap-router-tab{
            padding:0;
            margin-top: 20px;
            .wap-router-box{
                padding:0;
                margin: 0 0 10px;
                .wap-router-title{
                    width: 100%;
                    padding:15px 15px;
                    border-left:6px solid #95959E;
                    background: #F4F7FE;
                }
                .wap-cur{
                    border-left:6px solid #1F73B7;
                }
                .wap-router-list{
                    width: 100%;
                    .wap-router-li-item{
                        padding: 0 20px;
                        .step-item-box{
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            padding: 10px 0;
                            .step-item-group-left{
                                width:95%;
                                .step-item-dtxt{
                                    color: #616166;
                                    padding: 15px 0 10px;
                                }
                            }
                            .step-item-group-right{
                                width: 5%;
                                text-align: center;
                                .el-icon-arrow-down{font-size:20px;color: #95959E;}
                                .el-icon-arrow-up{font-size:20px;color: #95959E;}
                            }
                        }
                    }
                }
            }
        }
        
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        padding-bottom: 30px;
        .pc-banner{display: none;}
        .pc-router-tab{display: none;}
        .wap-banner{
            display:flex;
            margin-top: 10px;
            height:190px;
            width: 100%;
            background: url(~@/assets/images/problem_banner.png) no-repeat left top;
            background-size: 100% 100%;
            .wap-centrr{
                width: 100%;
                .wap-banner-title{
                    width: 100%;
                    padding:30px;
                    font-size: 30px;
                    text-align: center;
                }
                .wap-bannersearch{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    width: 80%;
                    height:40px;
                    line-height:40px;
                    margin: 0 auto;
                    background: #fff;
                    border-top-left-radius:4px;
                    border-bottom-left-radius:4px;
                    .wap-btnicon{
                        width:15%;
                        text-align: center;
                        background: #fff;
                        border-top-left-radius:4px;
                        border-bottom-left-radius:4px;
                        .el-icon-search{
                            line-height:40px;
                            font-size:20px;
                        }
                    }
                    .wap-btninput{
                        width: 65%;
                        position: relative;
                        border-radius:0;
                        input{
                            width: 100%;
                            height: 100%;
                            border: none;
                            outline: none;
                            padding:0 10px;
                            
                            border-radius:0;
                            background: transparent;
                        }
                        .wap-cancel{
                            display: inline-block;
                            position: absolute;
                            cursor: pointer;
                            right: 0;
                            top:0;
                            height:40px;
                            font-size:20px;
                            padding:0 10px;
                            background: #fff;
                        }
                    }
                    .wap-btnsear{
                        width:20%;
                        text-align: center;
                        
                        color: #fff;
                        background: #1f73b7;
                        border-top-right-radius:4px;
                        border-bottom-right-radius:4px;
                    }
                }
            }
            
        }
        .wap-router-tab{
            padding:0;
            margin-top: 20px;
            .wap-router-box{
                padding:0;
                margin: 0 0 10px;
                .wap-router-title{
                    width: 100%;
                    padding:15px 15px;
                    border-left:6px solid #95959E;
                    background: #F4F7FE;
                }
                .wap-cur{
                    border-left:6px solid #1F73B7;
                }
                .wap-router-list{
                    width: 100%;
                    .wap-router-li-item{
                        padding: 0 20px;
                        .step-item-box{
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            padding: 10px 0;
                            .step-item-group-left{
                                width:95%;
                                .step-item-dtxt{
                                    color: #616166;
                                    padding: 15px 0 10px;
                                }
                            }
                            .step-item-group-right{
                                width: 5%;
                                text-align: center;
                                .el-icon-arrow-down{font-size:20px;color: #95959E;}
                                .el-icon-arrow-up{font-size:20px;color: #95959E;}
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>